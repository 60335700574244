import React, { useContext, useEffect, useState, useCallback } from 'react';
import * as S from './NextButtonContainer.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const NextButtonContainer = props => {
  const {
    children,
    nextLayerId,
    answers,
    disableOnEmptyKey,
    validateAnswerKeys,
    extraObjects,
    insertQueryParam,
    queryParamString,
    clearPreviousLayers,
    triggerScrollToTop,
    insertAnswersToQueryParams,
    insertDatalayer,
    scrollTo = false
  } = props;

  const {
    currentLayer,
    setCurrentLayer,
    previousLayer,
    setPreviousLayer,
    answers: answersContext,
    setAnswers,
    extraObjects: extraObjectsContext,
    setExtraObjects
  } = useContext(FunnelContext);

  const [disabled, setDisabled] = useState(true);

  const [previousLayer2, setPreviousLayer2] = useLocalStorage(
    'previousLayer',
    []
  );
  const [currentLayer2, setCurrentLayer2] = useLocalStorage(
    'currentLayer',
    'layer-0'
  );
  const [answers2, setAnswers2] = useLocalStorage('answers', {});
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});

  const isDisabled = useCallback(() => {
    if (!disableOnEmptyKey && !validateAnswerKeys) {
      return false;
    }

    let results = false;

    if (disableOnEmptyKey !== '') {
      if (
        !answersContext[disableOnEmptyKey] ||
        answersContext[disableOnEmptyKey] === ``
      ) {
        results = true;
      }
    }
    if (validateAnswerKeys?.length > 0) {
      validateAnswerKeys.forEach(({ key }) => {
        if (!answersContext[key] || answersContext[key] === ``) {
          results = true;
        }
        return results;
      });
    }
    return results;
  }, [validateAnswerKeys, disableOnEmptyKey, answersContext]);

  useEffect(() => {
    setDisabled(isDisabled());
    let is_disabled = false;
    let disabledInterval;
    if (disableOnEmptyKey || validateAnswerKeys) {
      is_disabled = true;
      disabledInterval = setInterval(() => {
        if (is_disabled !== isDisabled()) {
          setDisabled(isDisabled());
          is_disabled = isDisabled();
        }
        // @Todo find better approach, that setInterval
        // if (isDisabled() === false) {
        //   clearInterval(disabledInterval);
        // }
      }, 500);
    }
    return () => {
      setDisabled(isDisabled());
      clearInterval(disabledInterval);
    };
  }, [
    answersContext,
    disabled,
    setDisabled,
    disableOnEmptyKey,
    validateAnswerKeys,
    isDisabled
  ]);

  const triggerClick = () => {
    if (Builder.isEditing) {
      return false;
    }

    if (scrollTo) {
      const element = document.getElementById(nextLayerId);
      element?.scrollIntoView();
      return null;
    }

    // set answers
    if (answers) {
      answers.map(({ key, value }) => {
        if (key) {
          if (insertQueryParam) {
            const newAnswers = Object.assign(answers2, {
              [key]: value
            });
            setAnswers2(newAnswers);
          } else {
            const newAnswers = Object.assign(answersContext, {
              [key]: value
            });
            setAnswers(newAnswers);
          }
        }
        return false;
      });
    }
    // set extraObjects
    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (key) {
          if (insertQueryParam) {
            const newExtraObjects = Object.assign(extraObjects2, {
              [key]: value
            });
            setExtraObjects2(newExtraObjects);
          } else {
            const newExtraObjects = Object.assign(extraObjectsContext, {
              [key]: value
            });
            setExtraObjects(newExtraObjects);
          }
        }
        return false;
      });
    }
    // set extraObjects
    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (key) {
          if (insertQueryParam) {
            const newExtraObjects = Object.assign(extraObjects2, {
              [key]: value
            });
            setExtraObjects2(newExtraObjects);
          } else {
            const newExtraObjects = Object.assign(extraObjectsContext, {
              [key]: value
            });
            setExtraObjects(newExtraObjects);
          }
        }
        return false;
      });
    }

    if (isDisabled()) {
      return;
    }

    setPreviousLayer([...previousLayer, currentLayer]);
    setCurrentLayer(nextLayerId);

    if (insertQueryParam) {
      if (clearPreviousLayers) {
        setPreviousLayer2([`layer-0`]);
      } else {
        setPreviousLayer2([...previousLayer2, currentLayer2]);
      }
      setCurrentLayer2(nextLayerId);

      const layerKey = queryParamString || 'layer';
      let params = new URLSearchParams(window.location.search);
      params.set(layerKey, nextLayerId);

      if (insertAnswersToQueryParams) {
        insertAnswersToQueryParams.map(({ key }) =>
          params.set(key, answersContext[key])
        );
      }

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    }

    if (insertDatalayer) {
      insertDatalayer.forEach(({ objects }) => {
        const datalayer = {};
        if (objects) {
          objects.forEach(({ key, value, valueInAnswers }) => {
            datalayer[key] = valueInAnswers ? answersContext[value] : value;
          });
        }
        window?.dataLayer?.push(datalayer);
      });
    }

    if (triggerScrollToTop) {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  };

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  return (
    <S.Container
      onClick={triggerClick}
      disabled={disabled}
      layerId={nextLayerId}
      editing={Builder.isEditing}
    >
      {children || noChildren()}
    </S.Container>
  );
};

export default NextButtonContainer;
